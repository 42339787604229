import React from "react"
import { Link } from "gatsby"

import MapShape2 from "../../assets/images/shape/map-shape2.png"

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="why-choose-us-content">
              <h2>Why Choose Us?</h2>
              <p>
                During 30 years in data analytics, we have built up
                comprehensive competencies that cover traditional and big data,
                data management, business intelligence (including self-service
                BI and data visualization), advanced data analytics, and data
                science.
              </p>
              <p>
                In 2015, we were rated A+ by BBB, recognized as the top IT
                company by Clutch, as well as featured on The Silicon Review
                listing among 10 Fastest Growing Data Analytics Companies.
              </p>

              <Link to="/about-us" className="default-btn">
                <i className="flaticon-right"></i>
                More About Us <span></span>
              </Link>

              <div className="map-shape2">
                <img src={MapShape2} alt="MapShape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
